import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as guestspqlf9RpjitMeta } from "/app/pages/guests.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as practicalQVHPlq9NMNMeta } from "/app/pages/practical.vue?macro=true";
import { default as _91slug_93wwHVCUKFHOMeta } from "/app/pages/programme/[slug].vue?macro=true";
import { default as index1lCezIa9rcMeta } from "/app/pages/programme/index.vue?macro=true";
import { default as ticketsLMEjejk3YqMeta } from "/app/pages/tickets.vue?macro=true";
export default [
  {
    name: "about___cs",
    path: "/o-art-vr",
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "guests___cs",
    path: "/hoste",
    component: () => import("/app/pages/guests.vue").then(m => m.default || m)
  },
  {
    name: "guests___en",
    path: "/en/guests",
    component: () => import("/app/pages/guests.vue").then(m => m.default || m)
  },
  {
    name: "index___cs",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "practical___cs",
    path: "/prakticke-informace",
    component: () => import("/app/pages/practical.vue").then(m => m.default || m)
  },
  {
    name: "practical___en",
    path: "/en/practical-information",
    component: () => import("/app/pages/practical.vue").then(m => m.default || m)
  },
  {
    name: "programme-slug___cs",
    path: "/program/:slug()",
    component: () => import("/app/pages/programme/[slug].vue").then(m => m.default || m)
  },
  {
    name: "programme-slug___en",
    path: "/en/programme/:slug()",
    component: () => import("/app/pages/programme/[slug].vue").then(m => m.default || m)
  },
  {
    name: "programme___cs",
    path: "/program",
    component: () => import("/app/pages/programme/index.vue").then(m => m.default || m)
  },
  {
    name: "programme___en",
    path: "/en/programme",
    component: () => import("/app/pages/programme/index.vue").then(m => m.default || m)
  },
  {
    name: "tickets___cs",
    path: "/tickets",
    component: () => import("/app/pages/tickets.vue").then(m => m.default || m)
  },
  {
    name: "tickets___en",
    path: "/en/tickets",
    component: () => import("/app/pages/tickets.vue").then(m => m.default || m)
  }
]