<script setup>
const { locale, t } = useI18n();
const error = useError();
const color = "purple";

const handleError = () => {
  clearError({
    redirect: "/",
  });
};
</script>

<template>
  <div class="flex full">
    <h1>{{ error.statusCode }}</h1>
    <h3>
      {{
        error.message.includes("Page not found")
          ? t("error.page-not-found")
          : error.message
      }}
    </h3>
    <button class="black border m-top-1" @click="handleError">
      {{ t("error.button.go-back") }}
    </button>
  </div>
</template>

<style>
.full {
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}
</style>
