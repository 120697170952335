
// @ts-nocheck


export const localeCodes =  [
  "cs",
  "en"
]

export const localeLoaders = {
  "cs": [{ key: "../locales/cs.js", load: () => import("../locales/cs.js?hash=86883749&locale=cs" /* webpackChunkName: "locale__app_locales_cs_js" */), cache: false }],
  "en": [{ key: "../locales/en.js", load: () => import("../locales/en.js?hash=bde023b2&locale=en" /* webpackChunkName: "locale__app_locales_en_js" */), cache: false }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "jsTsFormatResource": true
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "cs",
      "name": "CZ",
      "language": "cs-CZ",
      "files": [
        "/app/locales/cs.js"
      ]
    },
    {
      "code": "en",
      "name": "EN",
      "language": "en-US",
      "files": [
        "/app/locales/en.js"
      ]
    }
  ],
  "defaultLocale": "cs",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "index": {
      "cs": "/",
      "en": "/"
    },
    "about": {
      "cs": "/o-art-vr",
      "en": "/about"
    },
    "guests": {
      "cs": "/hoste",
      "en": "/guests"
    },
    "practical": {
      "cs": "/prakticke-informace",
      "en": "/practical-information"
    },
    "programme/index": {
      "cs": "/program",
      "en": "/programme"
    },
    "programme/[slug]": {
      "cs": "/program/[slug]",
      "en": "/programme/[slug]"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "seo": true,
  "legacy": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "cs",
    "name": "CZ",
    "language": "cs-CZ",
    "files": [
      {
        "path": "/app/locales/cs.js"
      }
    ]
  },
  {
    "code": "en",
    "name": "EN",
    "language": "en-US",
    "files": [
      {
        "path": "/app/locales/en.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
